@import "../../../styles/mixins";

main.home {
  > section {
    padding: 4rem;
  }
  .title {
    h1 {
      margin: 0;
    }
    padding: 10rem 0 3rem;
    text-align: center;
    font-family: var(--playfair);
    color: var(--stack);
    h2 {
      font-weight: 600;
    }
    h3 {
      font-weight: 400;
    }
  }

  .rapid-access-nav {
    .summary {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      li {
        padding-left: 0.5rem;
        a {
          margin-top: 1rem;
          font-size: 1.2rem;
          font-style: italic;
          color: var(--purple);
          transition : all .1s;
          &:hover {
            font-weight: bold;
            transition: all .1s ;
          }
          &:focus-visible {
            font-weight: bold;
            transition: all .1s ;
            outline-color: var(--blue);
            padding: .5rem;

          }
        }
      }
    }
  }

  //SECTIONS//
  .presentation {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: justify;

    h3 {
      font-weight: 700;
      padding-left: 1.5rem;
    }

    p,
    ul {
      @include reset-list;
      padding-left: 1.5rem;
    }
    .btn {
      margin: 3rem;
      align-self: flex-end;
      box-shadow: var(--box-s-btn);
      transition: all 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
  .svg {
    @include fd-jc-ai(column, center, center);
    width: 100%;
    position: relative;
    .blop {
      width: 25rem;
    }
    .hours {
      text-align: center;
      position: absolute;
      bottom: 10rem;
      left: 50%;
      transform: translateX(-50%);
      p {
        font-size: 1.2rem;
      }
    }
    .roll {
      align-self: center;
      width: 7rem;
      height: 7rem;
      margin-bottom: 10rem;
    }
  }
  .contact {
    margin-bottom: 0;
    color: var(--white);
    background-color: var(--blue);
    width: 100%;
    height: 8.6rem;
    font-weight: 700;
    line-height: 2.1rem;
    text-align: center;
    
    @include f-jc-center(center);
    a {
      @include reset-style;
      color: var(--white);
      font-size: 1.7rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
        color: var(--purple);       
      }
    }
    p {
      font-size: 1.7rem;
    }
  }
  .map {
    small {
      a {
        @include reset-style;
        font-size: 1rem;
        padding: 1rem;
      }
      article {
        padding: 1rem;
        p {
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
  .quote {
    text-align: center;
    font-weight: 700;
    p {
      text-align: center;
    }
    .logo-atelier-morgans {
      width: 12rem;
      align-self: center;
      margin-bottom: 3rem;
    }
    .btn {
      align-self: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .advertising {
      color : var(--ocre);
      font-weight: bold;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: var(--purple);
        transition: all 0.2s ease-in-out;
      }
      &:focus-visible {
        padding: 0.5rem;
        transition: all .2s;
        outline : 1px var(--blue) solid;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  main.home {
    max-width: 1400px;
    .title {
      margin-top: 20rem;
      padding-left: 5rem;
    }

    .rapid-access-nav {
      .summary {
        li {
          a {
            font-size: var(--normal-size);
          }
        }
      }
    }
    .presentation {
      .btn {
        height: 3rem;
        font-size: var(--normal-size);
      }
    }

    .svg {
      .roll {
        width: 10rem;
        height: 10rem;
        margin-bottom: 10rem;
      }
      .blop {
        width: 36rem;
      }
      .hours {
        bottom: 15rem;
      }
    }
    .presentation.contact {
      height: 15.6rem;
      a,
      p {
        font-size: 3rem;
        padding-bottom: 1.5rem;
      }
      a:focus-visible {
        transition: all 0.2s;
        outline: 1px var(--purple) solid;
        padding: .5rem;
      }
  
    }
    .presentation.map {
      margin-top: 0;
    }
  }
}
