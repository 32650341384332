// @import '../../../styles/mixins';

main.gallery {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  justify-content: center;
  align-items: center;
  > section {
    margin-bottom: 5rem;
    padding: 5rem;
  }
  .title {
    margin-top: 5rem;
    padding-left: 3rem;
    text-align: center;
    font-family: var(--playfair), 'Times New Roman', Times, serif;
    color: var(--stack);
    h2 {
      font-weight: 600;
    }
    h3 {
      font-weight: 400;
    }
  }
}
@media screen and (min-width: 390) and (max-width: 768px) {
  main.gallery {
    max-width: 768px;

  }
}
@media screen and (min-width: 1000px) {
  main.gallery {
    max-width: 1400px;
    margin: 0 auto;

    .title {
      margin-top: 20rem;
      padding-left: 5rem;
    }
  }
}
