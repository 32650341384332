@import "../../styles/mixins";

.nav-header {
  background-color: var(--blue);
  padding: 0.5rem 1rem;
  height: 2rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  h1 {
    margin-left: 1rem;
    margin-top: 0;
  }
  .content-nav {
    @include f-jc-center(space-between);
    img {
      width: 5rem;
    }

    .menu {
      display: block;
      font-size: 2.5rem;
      z-index: 99;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: var(--grey);
      }
    }

    .nav-list {
      position: fixed;
      @include fd-jc-ai(column, center, flex-start);
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      margin-top: 0;
      background-color: var(--blue);
      transform: translateX(-100%);
      z-index: 9;
      transition: all 0.5s ease-in-out;

      li {
        margin: 1rem;
        padding-left: 0;
        a {
          color: #333;
          padding: 1.5rem 2rem;
          display: inline-block;
          font-size: 2rem;
          transition: all 0.2s;
          color: var(--white);
          font-size: var(--nav-link);
          font-weight: 500;

          &:focus-visible {
            outline: 1px var(--white) solid;
            padding: 0.5rem;
          }
          &:hover {
            color: var(--purple);
            transform: translateX(2rem);
          }
        }
      }

      .btn {
        margin: 3rem;
        background-color: var(--ocre);
        border: none;
        color: var(--white);
        height: 2.5rem;
        border-radius: 1.2rem;
        width: auto;
        font-family: var(--montserrat-alternates);
        box-shadow: var(--box-s-btn);
        transition: all 0.1s ease-in-out;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
        a {
          color: var(--white);
        }
      }
      &.nav-list-show {
        transform: translateX(0);
      }
    }
  }
}
/////////////////////////////////////////////////////////
/// Mediaqueries

//Mediaqueries mobile
@media screen and (min-width: 350px) {
  //Fix the "svg" for a minimum screen usage of 350px corresponding to a Samsung Galaxy S8+
  .wave {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -99;
    margin-bottom: 5rem;
    width: 100%;
  }
}
/////////////////////////////////////////////////////////
/// Mediaqueries
@media screen and (min-width: 1050px) {
  .nav-header {
    .content-nav {
      img {
        padding-right: 2rem;
        width: 10rem;
      }
      .menu {
        display: none;
      }
      .nav-list {
        position: inherit;
        @include fd-jc-ai(row, space-around, center);
        transform: unset;
        height: 0;
        li {
          a {
            cursor: pointer;
            transform: translateX(1rem);
            color: var(--white);
            font-size: var(--nav-link);
            padding: 0;
            font-weight: 500;
            &:hover {
              color: var(--purple);
            }
            &:focus-visible {
              outline: 1px var(--white) solid;
              padding: 0.5rem;

            }
          }
        }
        .btn {
          height: auto;
          width: auto;
          padding: 0.5rem;
          a {
            color: var(--white);
          }
        }
      }
    }
  }
}
