:root {
    font-size: 62.5%;
    scroll-behavior: smooth;
    //color
    --blue : rgb(128, 184, 211);
    --highlighted-text : rgb(128 184 211 / 45%);
    --picture-icons : rgb(10, 101, 145);
    --edit-icons : rgb(20, 104, 85);
    --ocre : rgb(69, 101, 117);
    --beige : rgb(195, 190, 184);
    --stack :  rgb(140, 145, 138);
    --white : rgb(255, 255, 255);
    --emerald : rgb(33, 140, 116);
    --black : rgb(51, 51, 51);
    --red : rgb(168, 53, 53);
    --purple : rgb(128, 0, 128);
    --grey : rgb(128, 128, 128);
    --light-grey : rgb(201, 197, 197);
    --blue-loader : rgb(52, 152, 219);
    --stack-form :  rgb(90, 94, 89);




    //size
    //basic text
    --normal-size : 1.6rem;
    --little-size : 1.4rem;
    --legend-size : 1.2rem;
    --nav-link : 2rem;
    //h1
    --title-primary-s : 2.6rem;
    --title-primary-l : 3.2rem;
    //h2
    --title-secondary-s : 2.4rem;
    --title-secondary-l : 2.5rem;
    //h3 
    --title-tertiary-s : 1.9rem;
    --title-tertiary-l : 2rem;
    //h4
    --title-quaternary : 1.8rem;
    


    //font-family 
    --playfair : 'Playfair Display', serif;
    --montserrat-alternates : 'Montserrat Alternates', sans-serif;

    //border
    --border-radius : 1rem;
    --border-radius-input : 0.25rem;
    --border-radius-circle : 50%;
    --border-radius-btn : 1.2rem;

    --border-radius-picture : 1.5rem;

    //box-shadow
    --box-s-btn : 0px 4px 4px rgba(0, 0, 0, 0.25);
    --box-s-picture : 5px 5px 15px -5px var(--black);
    

    //overlay 
    --overlay-background : rgba(0, 0, 0, 0.7);
}

