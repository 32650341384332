@import "../../../styles/mixins";

main.services {
  > section {
    padding: 5rem;
    width: 100%;
  }
  .title {
    margin-top: 5rem;
    padding-left: 3rem;
    text-align: center;
    font-family: var(--playfair);
    color: var(--stack);
    h2 {
      font-weight: 600;
      margin-bottom: 2.5rem;
    }
    h3 {
      font-weight: 400;
      color: var(--black);
      font-family: var(--playfair);
    }
  }
  article {
    width: 100%;
    text-align: justify;
    img {
      margin-top: 2rem;
      height: 30rem;
      width: 100%;
      object-fit: cover;
      border-radius: var(--border-radius-picture);
      box-shadow: var(--box-s-picture);
    }
    .advertising {
      text-align: center;
      padding: 2rem;
      
      
      a {
      color : var(--ocre);
      font-weight: bold;
      transition: all 0.2s ease-in-out;
        &:hover {
          color: var(--purple);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////
/// Mediaqueries
//Mediaqueries tablet
@media screen and (min-width: 768px) {
  main.services {
    max-width: 768px;
    justify-content: center;
    align-items: center;
    .title {
      padding: 6rem 2rem;
    }
    article {
      img {
        height: 40rem;
      }
    }
  }
}

//Mediaqueries desktop
@media screen and (min-width: 1000px) {
  main.services {
    margin: 0 auto;
    max-width: 1400px;
    .title {
      margin-top: 20rem;
      padding-left: 5rem;
    }
    article {
      img {
        height: 70rem;
      }
    }
  }
}
