@import "../../styles/mixins";

.footer {
  height: 10rem;
  position: relative;
  background-color: var(--blue);
  overflow: hidden;

  @include f-jc-center(space-around);
  p {
    color : var(--white);
    font-weight: 500;
  }

  .legal-notice {
    color : var(--white);
    &:hover {
      color : var(--purple);
      transition: all .2s ease-in-out;
    }
    &:focus-visible {
      outline: 1px var(--white) solid;
      padding: 0.5rem;
    }
  }

  
  .social-network {
    a {
      font-size: 3rem;
      color: var(--white);
      &:hover {
        color: var(--purple);
        transition: all 0.2s ease-in-out;
      }
      &:focus-visible {
        outline: 1px var(--white) solid;
      }
    }
  }
}

