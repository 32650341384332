//Carousel in Home page

.container.carousel-home {
  .carousel-root {
    width: 100%;
    margin-top: 5rem;
    img {
      border-radius: 1.5rem;
      height: 35rem;
      width: 100%;
      object-fit: cover;
    }
    //Remove thumbnails
    .thumbs {
      display: none;
    }
    .slider {
      li {
        padding-left: 1.5rem;
      }
    }
  }
}


//Mediaqueries tablet
@media screen and (min-width: 768px) {
  //.container.carousel-home {
  .container {
    margin: 0 auto;
    .carousel-home {
      .carousel-root {
        img {
          height: 50rem;
        }
      }
    }
  }
}

//Mediaqueries desktop
@media screen and (min-width: 1000px) {
  .container {
    width: 100%;
    margin: 0 auto;
    .carousel-home {
    .carousel-root {
      align-self: center;
      width: 100%;
      img {
        max-width: 70%;
        height: 65rem;
      }
    }
  }}
}
