@import "../../../styles/mixins";

main.dashboard {
  width: 100vw;
  text-align: center;
  font-size: var(--little-size);

  @include fd-jc-ai(column, center, center);
  .section-content {
    .advice-dashboard {
      width: 100%;
      text-decoration: underline;
      color: var(--blue);
    }
    .dashboard-presentation {
      border: 2px dotted var(--blue);
      margin-bottom: 3rem;
      padding: 1rem;
      .legends-navigation {
        @include fd-jc-ai(column, center, center);
        padding-bottom: 2rem;
        li {
          text-align: justify;
          p:first-child {
            text-align: center;
          }
        }
      }
      .legends-icons {
        @include fd-jc-ai(column, center, center);
        padding-bottom: 1rem;
        p {
          text-align: justify;
        }
        svg {
          align-self: center;
          width: 100%;
        }
      }
      h4 {
        padding: 1rem 1rem;
      }
      li {
        padding: 1rem;
        text-align: start;
      }
      .important {
        color: red;
      }
      a {
        color: var(--blue);
        text-decoration: underline;
        &:hover {
          color: var(--red);
          transition: all 0.2s;
        }
      }
    }
    &.general {
      height: auto;
    }
    padding: 5rem;
    width: 100%;
    p {
      margin-bottom: 1rem;
    }
    h3 {
      padding-bottom: 2rem;
    }
    table {
      width: 30rem;
      margin: 10rem auto;
      border-collapse: collapse;
      table-layout: fixed;
      &.table-projects {
        font-size: var(--little-size);
        td {
          margin: 0;
        }
        td,
        th {
          overflow: auto;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        td:nth-child(4) {
          min-height: 10rem;
          max-height: 20rem;
          overflow: auto;
        }
        td:nth-child(5) {
          background-color: var(--highlighted-text);
          flex-direction: row;
          justify-content: space-evenly;
          border: 1px solid var(--blue);
        }
      }
      &.table-informations {
        td:nth-child(3) {
          background-color: var(--highlighted-text);
        }
        p {
          font-size: var(--little-size);
        }
      }
      &.table-sections {
        td:nth-child(2) {
          min-height: 10rem;
          max-height: 20rem;
          overflow: auto;
        }
        td:nth-child(3) {
          background-color: var(--highlighted-text);
        }
      }

      caption {
        font-size: 2.2rem;
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: left;
        text-transform: uppercase;
      }
      tr {
        display: flex;
        flex-direction: column;
        margin: 5rem 0;
      }

      th {
        background-color: var(--highlighted-text);
        border: 1px solid var(--blue);
        font-weight: bold;
        padding: 1rem;
        font-size: 1.8rem;
        text-align: center;
        vertical-align: middle;
        font-family: var(--playfair);
        text-transform: uppercase;
      }

      td {
        border: 1px solid var(--highlighted-text);
        padding: 1rem;
        font-size: 1.4rem;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:before {
          content: attr(data-label);
          font-weight: bold;
          margin-bottom: 0.5rem;
          text-decoration: underline;
          padding: 1rem;
        }
      }

      button {
        background-color: var(--white);
        border: 1px solid #ccc;
        border-radius: 3px;
        color: var(--black);
        cursor: pointer;
        padding: 0.5rem 1rem;
        transition: all 0.1s ease-in;

        &:hover {
          background-color: var(--blue);
        }
      }
    }
  }
  .nav-dashboard {
    background-color: var(--white);
    margin: 10rem;
    font-size: var(--normal-size);
    ul {
      @include reset-list;
      border: 1px solid var(--beige);
      border-radius: var(--border-radius);
      padding: 2rem;

      .navigation-dashboard {
        margin: 2rem 0;
        cursor: pointer;
      }
    }
  }
  .title {
    margin: 6rem 2rem;
  }
}

///////////////////////////////////////////////
/// Mediaqueries
///
@media screen and (min-width: 768px) {
  main.dashboard {
    font-size: var(--normal-size);
    .title {
      margin-top: 17rem;
    }
    table {
      &.table-projects {
        .list-pictures {
          p {
            font-size: var(--little-size);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  main.dashboard {
    max-width: 1400px;
    text-align: center;
    margin: 0 auto;
    .title {
      padding-top: 20rem;
    }
    .section-content {
      padding: 5rem;

      table {
        width: 90%;
        margin: 5rem auto;
      }
      .dashboard-presentation {
        .legends-icons {
          @include fd-jc-ai(row, center, center);
          p {
            text-align: center;
            font-size: var(--little-size);
          }
          gap: 1rem;
          padding-bottom: 1rem;
          svg {
            align-self: center;
            width: 100%;
            &:last-child {
              padding-bottom: 1rem;
            }
          }
        }
        .legends-navigation {
          @include fd-jc-ai(row, center, center);
          padding: 2rem 0;
          p {
            font-size: var(--little-size);
          }

          li {
            text-align: center;
          }
        }
      }
    }
    .redirection {
      margin-top: 20rem;
      height: 72rem;
    }
    .nav-dashboard {
      ul {
        @include f-jc-center(space-between);
        border: 1px solid var(--beige);
        border-radius: var(--border-radius);
        padding: 2rem;
        width: 60rem;
        cursor: pointer;
      }
    }
  }
}
