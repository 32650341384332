

//mixin style
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-style {
  text-decoration: none;
}

@mixin effect-btn($background, $color) {
  background-color: $background;
  color: $color;
  transition: all 0.2s;
  &:hover {
    background-color: $color;
    color: $background;
    transition: all 0.2s ease;
  }
}

// mixins flexbox && position



@mixin fd-jc-ai($direction, $position, $alignement) {
  display: flex;
  flex-direction: $direction;
  justify-content: $position;
  align-items: $alignement;
}

@mixin f-jc-center($position) {
  display: flex;
  justify-content: $position;
  align-items: center;
}

@mixin f-item-center {
  display: flex;
  align-items: center;
}
