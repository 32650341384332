@import "../../../styles/mixins";

.connection {
  font-family: var(--playfair);
  color: var(--stack);
  height: 100vh;

  h1 {
    font-size: 3rem;
    text-align: center;
    color: var(--stack);
  }

  .section {
    @include fd-jc-ai(column, center, center);
    height: 90vh;
    margin-top: 5rem;
    form {
      @include fd-jc-ai(column, center, center);
      margin-top: 5rem;
      background-color: var(--beige);
      padding: 3rem;
      border-radius: 0.5rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      width: 40rem;
      margin-bottom: 1rem;

      .btn {
        margin-top: 1rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: all 0.1s ease-in-out;
        &:hover {
          transform: scale(1.1);
          cursor : pointer;
        }
      }

    }
  }
}
