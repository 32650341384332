@import "../../../styles/mixins";
main.contact {
  @include fd-jc-ai(column, center, center);

  margin: 2rem 2rem;

  section {
    height: 70rem;
    article {
      padding-top: 2rem;
      a,
      p {
        color: var(--purple);
        font-weight: bold;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
          color: var(--red);
        }
      }
    }
  }
  .title {
    margin-top: 5rem;
    padding-left: 3rem;
    text-align: center;
    font-family: var(--playfair);
    color: var(--stack);
    h2 {
      font-weight: 600;
    }
    h3 {
      font-weight: 400;
      color: var(--black);
      font-style: italic;
      font-family: var(--playfair);
    }
  }

  form {
    font-family: var(--montserrat-alternates);
    padding: 2rem;
    display: flex;
    max-width: 30rem;
    background-color: var(--beige);
    color: var(--stack-form);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--stack);
    @include fd-jc-ai(column, center, center);
    border-radius: 5px;
    margin-top: 5.3rem;
    legend {
      align-self: end;
      font-size: var(--legend-size);
    }
    span {
      color: var(--red);
      font-weight: bold;
    }
    h4 {
      text-align: center;
      font-family: var(--playfair);
      color: var(--stack-form);
    }
    div {
      display: flex;
      flex-direction: column;
      width: 22rem;
      height: auto;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      input,
      select {
        font-family: Arial, Helvetica, sans-serif;
        border-radius: 5px;
        padding: 1rem 1.5rem 1rem 1.5rem;
        margin-bottom: 0.5rem;
        border: 1px solid var(--stack);
        color: rgba(0, 0, 0, 0.7);
        font-style: italic;
        font-size: 1.6rem;


        &:focus-visible {
          transition: all 0.2s;
          border-color: var(--white);
          background-color: var(--blue);
        }

        &::placeholder {
          padding: 0.5rem;
          margin: 0.5rem;
        }
      }
      label,
      p {
        text-align: left;
        margin-bottom: 0.5rem;
      }
      textarea {
        border-radius: 5px;
        padding: 1rem 1.5rem 1rem 1.5rem;
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 1.6rem;
        color :  rgba(0, 0, 0, 0.7);
        margin-bottom: 0.5rem;
        height: 20rem;
        border: 1px solid var(--stack);
        &::placeholder {
          padding: 0.5rem;
          margin: 0.5rem;
        }
      }
    }
    button {
      width: 8.9rem;
      height: 4.8rem;
      align-self: flex-end;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  main.contact {
    .container {
      height: 80rem;
    }
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .title {
      margin-top: 8rem;
      padding-left: 4rem;
    }
    form {
      min-width: 40rem;
      margin-bottom: 5rem;
    }
  }
}
@media screen and (min-width: 1000px) {
  main.contact {
    max-width: 1400px;
    margin: 0 auto;
    .title {
      margin-top: 20rem;

      padding-left: 5rem;
    }
    .container {
      height: 95rem;
      article {
        padding: 2rem;
      }
    }

    form {
      min-width: 70rem;
      margin-bottom: 8.3rem;
      div {
        width: 100%;
      }
    }
  }
}
