/////////////////////////////////////////////////////////
/// import scss
@use "./gardevoir";
@use "./variables";
@use "./fonts";
@import "./mixins";

/////////////////////////////////////////////////////////
/// Need it for fix the footer on bottom
#root {
  position: relative;
}

/////////////////////////////////////////////////////////
///GENERAL
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--little-size);
  font-family: var(--montserrat-alternates), Arial, Helvetica, sans-serif;
  background-color: var(--white);
}

* ul {
  @include reset-list;
}
* a {
  @include reset-style;
}

//Buttons
button {
  background-color: var(--blue);
  border: none;
  color: var(--white);
  height: 2.5rem;
  border-radius: 1.2rem;
  width: auto;
  font-family: var(--montserrat-alternates), Arial, Helvetica, sans-serif;
  &:focus-visible {
    outline: 1px var(--purple) solid;
  }
}

.btn-close {
  @include effect-btn(var(--red), var(--white));
}
.btn-send {
  @include effect-btn(var(--emerald), var(--white));
}
//Image
img {
  max-width: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
  &:focus-visible {
    outline : 1px var(--purple) solid;
  
}
}

.thumbnail-picture {
  width: 15rem;
  height: 10rem;
  object-fit: cover;
  margin-right: 4rem;
  box-shadow: 0px 10px 13px -7px var(--black),
    5px 5px 15px 5px rgba(102, 102, 102, 0);
  margin: 1rem 4rem 1rem auto;
}
.thumbnail-wrapp {
  padding: 0.5rem;
  flex-direction: row !important ;
  justify-content: center;
}
.thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
//Title
h1,
h2,
h3,
h4 {
  font-family: var(--playfair), "Times New Roman", Times, serif;
}

h1 {
  font-size: var(--title-primary-s);
}

h2 {
  font-size: var(--title-secondary-s);
}

h3 {
  font-size: var(--title-tertiary-s);
  margin-bottom: 1rem;

}

h4 {
  font-size: var(--title-quaternary);
  margin-bottom: 3rem;
}
//Decoration for some titles
.highlighted-text {
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  color: var(--black);
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    top: 67%;
    left: 11px;
    width: 100%;
    height: 30%;
    background-color: var(--highlighted-text);
    z-index: -1;
    transform: translateY(-50%);
  }
}
/////////////////////////////////////////////////////////
///Message displayed when the user performs an action
.message {
  font-weight: bold;
  font-style: italic;
  font-size: 1.4rem;
  margin: 1rem;
  text-align: center;
  &.error-message {
    color: var(--red);
  }
  &.success-message {
    color: var(--emerald);
  }
  &.redirection-message {
    margin-top: 20rem;
  }
}

.preview-picture {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    font-weight: 500;
    transition: all 0.2s;
  }
}
/////////////////////////////////////////////////////////
///Inputs
input,
textarea {
  display: block;
  width: 100%;
  margin: 1rem 0;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  }

  &[type="email"] {
    font-family: var(--montserrat-alternates), Arial, Helvetica, sans-serif;
  }

  &[type="password"] {
    font-family: var(--montserrat-alternates), Arial, Helvetica, sans-serif;
  }
}

/////////////////////////////////////////////////////////
/// Animations for some elements
.add-project {
  background-color: var(--emerald);
  padding: 1rem;
  height: auto;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  animation: scale 2s ease-in-out infinite;
  cursor: pointer;
  //On hover, stop the animation
  &:hover {
    animation-play-state: paused;
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
//nav on dahboard user
.selected-nav {
  background-color: var(--blue);
  padding: 1rem;
  border-radius: var(--border-radius);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  animation: bounce 0.4s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

//--Loader
.loader {
  margin: 0 auto;
  border: 8px solid var(--light-grey);
  border-top: 8px solid var(--blue-loader);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/////////////////////////////////////////////////////////
///Modals
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-background);
  z-index: 9999;
  @include f-jc-center(center);

  .modal {
    background: var(--blue);
    min-width: 40rem;
    max-width: 60rem;
    border-radius: var(--border-radius);
    padding: 2rem 1rem;
    text-align: center;
    max-height: 60rem;
    overflow: auto;
    h2 {
      margin-bottom: 2rem;
    }

    button {
      margin: 1rem;
      cursor: pointer;
      &.btn-delete {
        background-color: var(--red);
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: var(--emerald);
        }
      }
    }
  }
}
/////////////////////////////////////////////////////////
///Icons
.icon-failure {
  color: var(--red);
  font-size: 3rem;
  margin-left: 15rem;
}

.container-failure-message {
  text-align: center;
}
.icons-btn {
  background: none;
  color: var(--black);
  font-size: 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  &.delete {
    color: var(--red);
  }
  &.edit {
    color: var(--edit-icons);
  }
  &.add {
    color: var(--picture-icons);
  }
  &.logout {
    color: var(--red);
    font-size: 2.5rem;
    margin: 1rem;
  }
}
//-effect on hover of icons
.edit:hover,
.contact:hover,
.delete:hover,
.open:hover,
.add:hover,
.logout:hover {
  transition: all 0.2s;
  transform: scale(1.3);
}
/////////////////////////////////////////////////////////
/// Anchor
.anchor-link {
  position: fixed;
  right: 3rem;
  bottom: 10rem;
  @include f-jc-center(center);
  z-index: 999;

  width: 4rem;
  height: 4rem;
  background-color: var(--ocre);
  border-radius: var(--border-radius-circle);
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1rem);
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.5);
  }
}

.anchor-icon {
  color: var(--blue);
  font-size: 3rem;
}

/////////////////////////////////////////////////////////
///  Pagination

.pagination-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  .pagination-buttons-container {
    margin-top: 1rem;
    button {
      margin-right: 1.25rem;
      cursor : pointer;
      transition : all .2s;
      &:hover {
        transition : all .2s;
       color: var(--purple);
      }
    }
  }
}

.password-forgotten {
  font-style: italic;
  cursor : pointer;
  transition : all .2s;
  color : var(--stack);
  &:hover {
    transition : all .2s;
   color: var(--purple);
  }
}

/////////////////////////////////////////////////////////
/// Mediaqueries
/// - tablet
@media screen and (min-width: 768px) {
  * p {
    font-size: var(--normal-size);
  }
  * h1 {
    font-size: var(--title-primary-l);
  }
  * h2 {
    font-size: var(--title-secondary-l);
  }
  * h3 {
    font-size: var(--title-tertiary-l);
    margin-bottom: 1rem;
  }
}
