@import "../../../styles/mixins";

main.mentions-legales {
  section {
    padding: 5rem;
    width: 100%;
  }
  .title {
    margin: 5rem 0;
    padding-left: 3rem;
    text-align: center;
    font-family: var(--playfair);
    color: var(--stack);
  }
  article {
    width: 100%;
    text-align: justify;
    span {
        font-style: italic;
    }
  }
}
//Mediaqueries tablet
@media screen and (min-width: 768px) {
  main.mentions-legales {
    max-width: 768px;
    .title {
      padding-bottom : 4rem;
      margin: 10rem 0;
    }
  }
}

//Mediaqueries desktop
@media screen and (min-width: 1000px) {
  main.mentions-legales {
    margin: 0 auto;
    max-width: 1400px;
    .title {
      margin-top: 20rem;
    }
  }
}
