@import "../../../styles/mixins";
.container {
    text-align: center;
    width: 100%;
    height: 40rem;
    margin-top: 7rem;
    @include fd-jc-ai(column, center, center);
    .link {
        font-weight: bold;
        text-decoration: underline;
    }
}